import React, { Suspense, lazy } from "react";
import ReactDOM from 'react-dom';
import { LeapFrog } from '@uiball/loaders'
import "./app.scss";
import { GlobalProvider } from "./context/GlobalState";
import { BrowserRouter, Routes, Route } from "react-router-dom";
const MobidityAndMortalityData = lazy(() => import("./page/MobidityAndMortalityData"));
const VacinationCoverage = lazy(() => import("./page/VaccinationCoverage"));
function App() {
  return (
    <GlobalProvider>
      <BrowserRouter>
      <Suspense fallback={
        <div className="center-loader justify-content-center align-items-center d-flex">
          <LeapFrog size={40} speed={2.5}  color="#EF5B5B" />
        </div>
      }>
        <Routes>
          <Route
            path="/"
            element={<MobidityAndMortalityData />}
          />
          <Route
            path="/vaccination_coverage"
            element={<VacinationCoverage />}
          />
        </Routes>
        </Suspense>
      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;
